import React from 'react';

var BannerLanding = function BannerLanding(props) {
  return React.createElement("section", {
    id: "banner",
    className: "style2"
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("header", {
    className: "major"
  }, React.createElement("h1", null, "Landing")), React.createElement("div", {
    className: "content"
  }, React.createElement("p", null, "Lorem ipsum dolor sit amet nullam consequat", React.createElement("br", null), "sed veroeros. tempus adipiscing nulla."))));
};

export default BannerLanding;