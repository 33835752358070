import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import BannerLanding from '../components/BannerLanding';
import pic08 from '../assets/images/pic08.jpg';
import pic09 from '../assets/images/pic09.jpg';
import pic10 from '../assets/images/pic10.jpg';

var Landing = function Landing(props) {
  return React.createElement(Layout, null, React.createElement(Helmet, null, React.createElement("title", null, "Landing - Forty by HTML5 UP"), React.createElement("meta", {
    name: "description",
    content: "Landing Page"
  })), React.createElement(BannerLanding, null), React.createElement("div", {
    id: "main"
  }, React.createElement("section", {
    id: "one"
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("header", {
    className: "major"
  }, React.createElement("h2", null, "Sed amet aliquam")), React.createElement("p", null, "Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa vel lacinia pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis libero. Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna."))), React.createElement("section", {
    id: "two",
    className: "spotlights"
  }, React.createElement("section", null, React.createElement(Link, {
    to: "/generic",
    className: "image"
  }, React.createElement("img", {
    src: pic08,
    alt: ""
  })), React.createElement("div", {
    className: "content"
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("header", {
    className: "major"
  }, React.createElement("h3", null, "Orci maecenas")), React.createElement("p", null, "Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa sed magna lacinia magna pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis tempus."), React.createElement("ul", {
    className: "actions"
  }, React.createElement("li", null, React.createElement(Link, {
    to: "/generic",
    className: "button"
  }, "Learn more")))))), React.createElement("section", null, React.createElement(Link, {
    to: "/generic",
    className: "image"
  }, React.createElement("img", {
    src: pic09,
    alt: ""
  })), React.createElement("div", {
    className: "content"
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("header", {
    className: "major"
  }, React.createElement("h3", null, "Rhoncus magna")), React.createElement("p", null, "Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa sed magna lacinia magna pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis tempus."), React.createElement("ul", {
    className: "actions"
  }, React.createElement("li", null, React.createElement(Link, {
    to: "/generic",
    className: "button"
  }, "Learn more")))))), React.createElement("section", null, React.createElement(Link, {
    to: "/generic",
    className: "image"
  }, React.createElement("img", {
    src: pic10,
    alt: ""
  })), React.createElement("div", {
    className: "content"
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("header", {
    className: "major"
  }, React.createElement("h3", null, "Sed nunc ligula")), React.createElement("p", null, "Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa sed magna lacinia magna pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis tempus."), React.createElement("ul", {
    className: "actions"
  }, React.createElement("li", null, React.createElement(Link, {
    to: "/generic",
    className: "button"
  }, "Learn more")))))))));
};

export default Landing;